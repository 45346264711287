// @vue/component
export default {
  props: {
    duration: {
      type: String,
      required: true,
    },
    result: {
      type: String,
      required: true,
    },
  },
};
