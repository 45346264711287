import cardList from "@/components/card-list/card-list.vue";

import manage from "@/assets/img/how-we-work/manage.svg";
import offshore from "@/assets/img/how-we-work/offshore.svg";
import project from "@/assets/img/how-we-work/project.svg";

export default {
  components: {
    cardList,
  },
  data() {
    return {
      title: "How we can work together",
      description: "",
      items: [
        {
          image: offshore,
          title: "Offshore Development Team",
          text: "Billing based on daily rate card and flexible allocation of expertise",
        },
        {
          image: manage,
          title: "Managed support and maintenance",
          text: "24/7/365 with state of the art service-level agreement, real-time monitoring and alerting and proactive approach.",
        },
        {
          image: project,
          title: "Project-Based",
          text: "Fixed-price contract",
        },
      ],
    };
  },
};
