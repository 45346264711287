import cardList from "@/components/card-list/card-list.vue";

import odds from "@/assets/img/expertise/fixed.svg";
import betting from "@/assets/img/expertise/betting.svg";
import ball from "@/assets/img/expertise/football.svg";
import payment from "@/assets/img/expertise/payment.svg";
import web from "@/assets/img/expertise/web.svg";
import agile from "@/assets/img/expertise/agile.svg";
import promotion from "@/assets/img/expertise/promotion.svg";
import gambling from "@/assets/img/expertise/gambling.svg";

export default {
  components: {
    cardList,
  },
  data() {
    return {
      title: "Our expertise",
      description:
        "<p>Intro to Case Studies NeonCube is the fusion of agile software development with highly-scaled platform operations innovation in gaming and betting with reliability and security experience.</p><p>Intro to Case Studies NeonCube is the fusion of agile software development with highly-scaled platform operations innovation in gaming and betting with reliability and security experience.</p>",
      items: [
        {
          image: odds,
          title:
            "Fixed and Spread Odds betting<br>Pools and Tote style betting<br>Bet Exchanges<br>Casino",
          big: true,
        },
        {
          image: betting,
          title: "Betting & Gaming Platforms",
          text: "OpenBet, NYX, IGT, Lottorisq",
        },
        {
          image: ball,
          title: "Live Sports Data",
          text: "We integrate with RunningBall, SpoCoSy, Sporting",
        },
        {
          image: payment,
          title: "Payment & Wallet Systems",
          text: "PayPal, Boku, Kalixa, Mastercard",
        },
        {
          image: web,
          title: "Web & Mobile Analytics",
        },
        {
          image: agile,
          title: "Agile Software Development",
          text: "Perfect communication, flexibility and quick response to changing requirements thanks to small dynamic flexible teams that understand gambling industry and are experienced in working with large international businesses.",
        },
        {
          image: promotion,
          title: "Personalized Promotions",
          text: "Using big data to create real-time highly-targeted offers",
        },
        {
          image: gambling,
          title: "UK Gambling Commission Regulatory Compliance",
          text: "Geofencing, age verification, deposit limit, self-exclusion",
        },
      ],
    };
  },
};
