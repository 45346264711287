import { mapState } from "vuex";
import cardPerson from "@/components/card-person/card-person.vue";

export default {
  components: {
    cardPerson,
  },
  computed: {
    ...mapState("team", ["team"]),
  },
};
