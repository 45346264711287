import artur from "@/assets/img/team/artur.webp";
import jacek from "@/assets/img/team/jacek.webp";
import jakub from "@/assets/img/team/jakub.webp";
import michal1 from "@/assets/img/team/michal1.webp";
import michal2 from "@/assets/img/team/michal2.webp";
import pawel from "@/assets/img/team/pawel.webp";

export default {
  namespaced: true,
  state: {
    team: {
      artur: {
        name: "Artur Grzybowski",
        image: artur,
        shortDescription: "Expert - MediaTech",
      },
      michal1: {
        name: "Michał Ślaga",
        image: michal1,
        shortDescription: "Expert - MedTech",
      },
      jakub: {
        name: "Jakub Mrowiec",
        image: jakub,
        shortDescription: "Expert - MediaTech",
      },
      pawel: {
        name: "Paweł Gołda",
        image: pawel,
        shortDescription: "Expert - TravelTech",
      },
      michal2: {
        name: "Michał Smoliński",
        image: michal2,
        shortDescription: "Expert - MedTech",
      },
      jacek: {
        name: "Jacek Nosal",
        image: jacek,
        shortDescription: "Expert - TravelTech",
      },
    },
  },
};
