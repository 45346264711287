import Vue from "vue";
import Vuex from "vuex";
import team from "./modules/team";
import testimonials from "./modules/testimonials";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    team,
    testimonials,
  },
});
