var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-[url(/box-chat.webp)] bg-cover rounded-lg p-6 md:p-12 md:px-32 mb-6"},[_c('div',{staticClass:"grid gap-6"},_vm._l((_vm.content),function(chat,index){return _c('div',{key:index,staticClass:"flex gap-4 items-end",class:{
      'ml-14': index % 2 !== 0,
      'mr-14': index % 2 === 0
    }},[_c('div',{class:{ 'order-last': index % 2 !== 0 }},[_c('div',{staticClass:"rounded-full size-10 grid place-content-center text-2xl",class:{
          'bg-[#69bfc7]': chat.author !== 'Neoncube',
          'bg-[#4348C0]': chat.author === 'Neoncube',
        }},[(chat.author === 'Neoncube')?_c('IconNeoncube',{staticClass:"text-[#58fefc] size-6"}):_c('span',[_vm._v(_vm._s(chat.author[0]))])],1)]),_c('div',{staticClass:"p-4 rounded-lg",class:{
        'bg-[#24205D] text-[#CAF3F5]': chat.author !== 'Neoncube',
        'bg-[#4348C0] text-[#58FEFC]': chat.author === 'Neoncube',
        'rounded-br': index % 2 !== 0,
        'rounded-bl': index % 2 === 0
      }},[_vm._v(_vm._s(chat.content))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }