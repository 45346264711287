import { render, staticRenderFns } from "./element-box-time-effect.vue?vue&type=template&id=54964ee3"
import script from "./element-box-time-effect.js?vue&type=script&lang=js&external"
export * from "./element-box-time-effect.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports