import cardList from "@/components/card-list/card-list.vue";

import journey from "@/assets/img/what-we-do/journey.svg";
import retail from "@/assets/img/what-we-do/retail.svg";
import design from "@/assets/img/what-we-do/design.svg";
import app from "@/assets/img/what-we-do/app.svg";
import website from "@/assets/img/what-we-do/website.svg";
import offline from "@/assets/img/what-we-do/offline.svg";
import livefootball from "@/assets/img/what-we-do/livefootball.svg";
import livehorse from "@/assets/img/what-we-do/livehorse.svg";
import update from "@/assets/img/what-we-do/update.svg";
import scalable from "@/assets/img/what-we-do/scalable.svg";
import security from "@/assets/img/what-we-do/security.svg";
import disaster from "@/assets/img/what-we-do/disaster.svg";

export default {
  components: {
    cardList,
  },
  data() {
    return {
      title: "What we do",
      description: "",
      items: [
        {
          image: journey,
          title: "Web/cloud adoption",
          bullets: [
            "Scalable, resilient backend platforms",
            "Industry-standard security",
            "Disaster Prevention and Business Continuity Management",
          ],
        },
        {
          image: update,
          title: "MVP for startups",
          bullets: [
            "Design great mobile & web experience",
            "Mobile Apps for iOS and Android",
            "Lightning-fast, responsive websites that look good on desktop and mobile",
          ],
        },
        {
          image: scalable,
          title: "ML/AI",
          bullets: [
            "Moving concept AI/ML work to cloud service",
            "Monitoring, maintenance and support of AI/ML algorithm services",
          ],
        },
        // {
        //   image: journey,
        //   title: 'Engaging user journeys',
        //   text: 'Social betting, instant gratification and feedback loops, dynamic real-time leaderboards, incentive management based on customer behaviour, syndicated betting platforms.',
        // },
        // {
        //   image: retail,
        //   title: 'Help migrate from retail to online',
        //   text: 'Designing journeys that get betting shop customers online.',
        // },
        // {
        //   image: design,
        //   title: 'Design great mobile & web experience',
        //   text: 'Fast, sleek, native & hybrid apps with live data updates, push notifications and more.',
        // },
        // {
        //   image: app,
        //   title: 'Mobile Apps for iOS and Android',
        //   text: 'Fast, sleek, native & hybrid apps with live data updates, push notifications and more.',
        // },
        // {
        //   image: website,
        //   title: 'Websites',
        //   text: 'Lightning-fast, responsive websites that look good on desktop and mobile.',
        // },
        // {
        //   image: offline,
        //   title: 'Offline sync-up',
        //   text: 'Websites that work wherever you are even when not connected to the network.',
        // },
        // {
        //   image: livefootball,
        //   title: 'Live Football Scoreboards',
        //   text: 'Match event updates delivered instantly and visually presented.',
        // },
        // {
        //   image: livehorse,
        //   title: 'Live Horse Racing Scoreboards',
        //   text: 'Merging live data from different providers for enhanced accuracy.',
        // },
        // {
        //   image: update,
        //   title: 'Instant Updates',
        //   text: 'Mobile push notification management.',
        // },
        // {
        //   image: scalable,
        //   title: 'Scalable, resilient backend platforms',
        //   text: 'Handling peak surges while keeping data integrity and low infrastructure costs.',
        // },
        // {
        //   image: security,
        //   title: 'Industry-standard security',
        //   text: 'Sophisticated encryption and personal data protection to ensure fair gaming and to prevent data tampering and leakage.',
        // },
        // {
        //   image: disaster,
        //   title: 'Disaster Prevention and Business Continuity Management',
        //   text: 'For reliable, continuous service with 24/7/365 support from the techops team.',
        // },
      ],
    };
  },
};
