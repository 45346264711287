export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    shortDescription: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    dataToLink: {
      type: Object,
      required: false,
    },
  },
  methods: {
    doAction() {
      return this.$router.push(this.dataToLink);
    },
  },
};
