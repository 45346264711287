import cardBox from "@/components/card-box/card-box.vue";
import { mapState } from "vuex";

export default {
  components: {
    cardBox,
  },
  data() {
    return {
      title: "Testimonials",
      description:
        "Intro to testimonials NeonCube is the fusion of agile software development with highly-scaled platform operations innovation in gaming and betting with reliability and security experience.",
    };
  },

  computed: {
    ...mapState("testimonials", ["testimonials"]),
  },
  methods: {
    noop() {
      return Promise.resolve();
    },
  },
};
