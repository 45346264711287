import testimonialTemplate from "@/components/testimonial/testimonial-template/testimonial-template.vue";
import pageTemplate from "@/components/page-template/page-template.vue";
import { mapState } from "vuex";

export default {
  components: {
    testimonialTemplate,
    pageTemplate,
  },
  computed: {
    ...mapState("testimonials", ["testimonials"]),
  },
  methods: {
    getData() {
      return this.testimonials[this.$route.params.name];
    },
    isCorrectName() {
      return this.testimonials[this.$route.params.name];
    },
  },
};
