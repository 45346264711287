import { mapState } from "vuex";
import pageTemplate from "@/components/page-template/page-template.vue";

export default {
  components: {
    pageTemplate,
  },
  stats: {
    user: {
      type: Object,
    },
  },
  computed: {
    ...mapState("team", ["team"]),
  },
  created() {
    this.user = this.team[this.$route.params.name];
  },
};
