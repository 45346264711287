export default {
  props: {
    details: {
      type: Object,
    },
    buttonText: {
      type: String,
    },
    action: {
      type: Function,
      required: false,
    },
    h4: Boolean,
  },
  methods: {
    doAction() {
      return this.action().then(() => {
        this.$router.push({
          name: this.details.sectionName,
          params: { name: this.details.testimonialName },
        });
      });
    },
  },
};
