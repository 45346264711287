import pageTemplate from "@/components/page-template/page-template.vue";

export default {
  components: {
    pageTemplate,
  },
  props: {
    offer: {
      type: Object,
    },
  },
};
