import Vue from "vue";
import Router from "vue-router";
import index from "@/components/index/index.vue";
import testimonial from "@/components/testimonial/testimonial.vue";
import jobs from "@/components/jobs/jobs.vue";
import about from "@/components/about/about.vue";
import blogArticles from "@/components/blog/blog-articles/blog-articles.vue";
import blogArticle from "@/components/blog/blog-article/blog-article.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // @TODO dont know why but it does not scroll to
      // a saved position for me at all, plz someone check it
      window.setTimeout(() => {
        window.scrollTo(savedPosition.x, savedPosition.y);
      }, 100);
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "index",
      component: index,
    },
    {
      path: "/testimonials/:name",
      name: "testimonials",
      component: testimonial,
      props: true,
    },
    {
      path: "/jobs",
      name: "jobs",
      component: jobs,
    },
    {
      path: "/about/:name",
      name: "about",
      component: about,
    },
    {
      path: "/blog",
      name: "blog",
      component: blogArticles,
    },
    {
      path: "/blog/:slug",
      name: "blog-article",
      component: blogArticle,
      props: true,
    },
  ],
});
