import globalHeader from "@/components/global-header/global-header.vue";
import sectionContact from "@/components/section-contact/section-contact.vue";

import articlesMixin from "../articles-mixin";

export default {
  components: {
    globalHeader,
    sectionContact,
  },
  mixins: [articlesMixin],
  computed: {
    filteredArticles() {
      return this.articles.filter((i) => i.public);
    },
  },
};
