import pageTemplate from "@/components/page-template/page-template.vue";
import jobOffer from "@/components/jobs/job-offer/job-offer.vue";

export default {
  components: {
    pageTemplate,
    jobOffer,
  },
  data() {
    return {
      offer: {
        frontend: {
          tags: ["javascript", "Vue, React"],
          amount: "8 000 - 14 000 PLN",
        },
      },
    };
  },
};
