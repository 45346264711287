import { render, staticRenderFns } from "./section-what-we-do.vue?vue&type=template&id=e859033a"
import script from "./section-what-we-do.js?vue&type=script&lang=js&external"
export * from "./section-what-we-do.js?vue&type=script&lang=js&external"
import style0 from "./section-what-we-do.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports