import "@/styles/foundation.scss";
import "@/styles/global.scss";

import Vue from "vue";
import store from "@/store";
import router from "@/router";
import VueLazyload from "vue-lazyload";
import app from "@/components/app/app.vue";

Vue.config.productionTip = false;

Vue.use(VueLazyload, {
  observer: true,
});

Vue.directive("scroll", {
  inserted: (el) => {
    window.addEventListener("scroll", () => {
      if (el && el.offsetTop !== 0) {
        const positionSection = el.offsetTop;
        if (el.classList.value.indexOf("scrollHide") === -1) {
          el.classList.add("scroll");
          el.classList.remove("scrollShow");
        }
        if (positionSection - window.innerHeight * 0.7 < window.scrollY) {
          el.classList.add("scrollShow");
        }
      }
    });
  },
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  router,
  render: (h) => h(app),
});
