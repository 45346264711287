import { render, staticRenderFns } from "./element-box-chat.vue?vue&type=template&id=407871c4"
import script from "./element-box-chat.js?vue&type=script&lang=js&external"
export * from "./element-box-chat.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports