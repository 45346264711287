import { render, staticRenderFns } from "./testimonial-template.vue?vue&type=template&id=c7522aaa"
import script from "./testimonial-template.js?vue&type=script&lang=js&external"
export * from "./testimonial-template.js?vue&type=script&lang=js&external"
import style0 from "./testimonial-template.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports