export default {
  props: {
    params: {
      type: Object,
    },
    details: {
      type: Object,
    },
  },
};
