export default {
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    },
    bullets: {
      type: Array,
      default: () => [],
    },
    h4: Boolean,
  },
};
