export default {
  data() {
    return {
      mobileMenuOpen: false,
      menu: [
        {
          text: "Who we are",
          href: "#who-we-are",
        },
        {
          text: "What we do",
          href: "#what-we-do",
        },
        {
          text: "Our Experts",
          href: "#our-experts",
        },
        // {
        //   text: 'Case studies',
        //   href: '#',
        // },
        // {
        //   text: 'Our expertise',
        //   href: '#our-expertise',
        // },
        {
          text: "How we work",
          href: "#how-we-work",
        },
        // {
        //   text: 'Testimonials',
        //   href: '#testimonials',
        // },
        {
          text: "Locations",
          href: "#locations",
        },
        {
          text: "Contact",
          href: "#contact",
        },
        {
          text: "Blog",
          to: { name: "blog" },
        },
      ],
    };
  },
  computed: {
    classObject() {
      return {
        active: this.mobileMenuOpen,
      };
    },
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    async goToElement(el) {
      if (this.$route.name !== "index") {
        this.$router.push({ name: "index" });
        await this.$nextTick();
      }
      this.mobileMenuOpen = false;
      setTimeout(() => {
        const position =
          document.getElementById(el.replace("#", "")).offsetTop - 50;
        window.scrollTo({
          top: position,
          behavior: "smooth",
        });
      }, 100);
    },
  },
};
