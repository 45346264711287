import axios from "axios";

export default {
  data() {
    return {
      isSubmitting: false,
      submitResult: null,
      fields: {
        name: null,
        phone: null,
        email: null,
        message: null,
      },
    };
  },
  methods: {
    async onSubmit() {
      if (this.isSubmitting) {
        return;
      }
      this.submitResult = null;
      this.isSubmitting = true;

      try {
        const response = await axios({
          method: "post",
          url: "/api/contact-form",
          data: { ...this.fields },
        });
        this.submitResult = response.status === 200;
        if (this.submitResult) {
          this.fields = {
            name: null,
            phone: null,
            email: null,
            message: null,
          };
        }
      } catch (e) {
        this.submitResult = false;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
