import IconNeoncube from "@/assets/icons/icon-neoncube.vue";

// @vue/component
export default {
  components: {
    IconNeoncube,
  },
  props: {
    content: {
      type: Array,
      required: true,
    },
  },
};
