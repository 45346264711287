import IconTickCircle from "@/assets/icons/icon-tick-circle.vue";
import IconNeoncube from "@/assets/icons/icon-neoncube.vue";

import locustPy from "../../../../../public/blog/load-testing-with-locust-io/locust.py.js";

// @vue/component
export default {
  components: {
    IconTickCircle,
    IconNeoncube,
  },
  data() {
    return {
      codeFiles: {
        "locust.py": locustPy,
      },
    };
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    discussion: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    if (this.$refs.code) {
      window.hljs.highlightAll();
    }
  },
  methods: {
    prepareContent(content) {
      return Array.isArray(content) ? content : [{ text: content }];
    },
  },
};
