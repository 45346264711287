import loadTestingWithLocustIo from "../../../public/blog/load-testing-with-locust-io/article.json";
import implementingASupportTeam from "../../../public/blog/implementing-a-support-team/article.json";
import storyOf21SecondsToKnow from "../../../public/blog/story-of-21-seconds-to-know/article.json";
import manyFormatsManyApproaches from "../../../public/blog/many-formats-many-approaches/article.json";
import progressiveWebAppsVsNativeWebApps from "../../../public/blog/progressive-web-apps-vs-native-web-apps/article.json";
import promptEngineering from "../../../public/blog/prompt-engineering-personalisation-of-hotel-search-with-chat-gpt/article.json";

export default {
  data() {
    return {
      articles: [
        promptEngineering,
        progressiveWebAppsVsNativeWebApps,
        manyFormatsManyApproaches,
        storyOf21SecondsToKnow,
        implementingASupportTeam,
        loadTestingWithLocustIo
      ],
    };
  },
};
