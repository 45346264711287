import itvLogo from "@/assets/img/testimonials/client-case-itv.svg";
import itvBackground from "@/assets/img/testimonials/itv.png";

export default {
  namespaced: true,
  state: {
    testimonials: {
      itv: {
        logo: itvLogo,
        background: itvBackground,
        name: "ITV Competition Portal",
        title: "ITV",
        testimonialName: "itv",
        sectionName: "testimonials",
        tags: ["ux/ui", "web", "backend"],
        description:
          'ITV has selected Neon Cube to manage and develop its online competition portal.<br><br> The competition portal, <a href="https://www.itv.com/win" target="_blank">www.itv.com / win</a>, is promoted on ITV’s flagship Daytime shows, including Good Morning Britain, Lorraine, This Morning and Loose Women and peak time Entertainment shows.<br><br> All viewers need to do is register their mobile number on the site and enter for a chance to win huge prizes. Payment is made either via Paypal or by charging it directly to their mobile bill.Peter Mossman, Director of Production and Strategic Delivery, Interactive at ITV, has ambitious plans to develop the service further. <br><br>ITV started working with Neoncube as their development partner for the continued technical development and operational management of their competition proposition.',
        comments: [
          {
            text: "Neoncube have that rare ability to quickly understand what we need and to develop technical solutions rapidly and cost - effectively. We can rely on them to develop the portal in line with our ambition.",
            author: "Peter Mossman",
            position:
              "Director of Production and Strategic Delivery, Interactive @ ITV",
            textPart2:
              "Neon Cube specialises in handling high volumes of data over a short period of time and works to ITV’s very high standards and allows elegant management of the peaks of traffic during popular shows like Ant and Dec’s Saturday Night Takeaway. This has further supported ITV’s on air Interactive Strategy.",
          },
          {
            text: "We are honoured to be selected by ITV, the UK’s biggest commercial broadcaster. We have dedicated a team to ITV, so they develop a good understanding  and a close working relationship. <br><br>We look forward to developing the competition portal into a major destination for ITV viewers.",
            author: "Andy Shaw",
            position: "Managing Director @ Neoncube",
            textPart2: "",
          },
        ],
      },
      // getaway: {
      //   logo: null,
      //   background: null,
      //   name: 'Getaway',
      //   title: 'Getaway',
      //   testimonialName: 'getaway',
      //   sectionName: 'testimonials',
      //   tags: ['ux/ui', 'web', 'backend'],
      //   description: '',
      //   comments: [],
      // },
      // radpoint: {
      //   logo: null,
      //   name: 'Radpint',
      //   title: 'Radpint',
      //   testimonialName: 'radpoint',
      //   sectionName: 'testimonials',
      //   tags: ['ux/ui', 'web', 'backend'],
      //   description: '',
      //   comments: [],
      // },
    },
  },
};
