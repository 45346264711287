import globalHeader from "@/components/global-header/global-header.vue";
import sectionHello from "@/components/section-hello/section-hello.vue";
import sectionTech from "@/components/section-tech/section-tech.vue";
import sectionWhoWeAre from "@/components/section-who-we-are-new/section-who-we-are-new.vue";
import sectionWhatWeDo from "@/components/section-what-we-do/section-what-we-do.vue";
import sectionOurExpertise from "@/components/section-our-expertise/section-our-expertise.vue";
import sectionHowWeWork from "@/components/section-how-we-work/section-how-we-work.vue";
import sectionAddress from "@/components/section-address/section-address.vue";
import sectionContact from "@/components/section-contact/section-contact.vue";
import sectionTestimonials from "@/components/section-testimonials/section-testimonials.vue";
import sectionOurExperts from "@/components/section-our-experts/section-our-experts.vue";

export default {
  components: {
    globalHeader,
    sectionHello,
    sectionTech,
    sectionWhoWeAre,
    sectionWhatWeDo,
    sectionOurExpertise,
    sectionHowWeWork,
    sectionAddress,
    sectionContact,
    sectionTestimonials,
    sectionOurExperts,
  },
};
